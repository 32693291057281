<template>
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="multisteps-form">
              <div class="row">
                <div class="mx-auto mt-4 mb-3 col-12 col-lg-12 mb-sm-5">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Informações"
                      :class="activeStep >= 0 ? activeClass : ''"
                      @click="activeStep = 0"
                    >
                      <span>1. Informações</span>
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Imagens"
                      v-if="produto.tipo_produto == 'normal'"
                      :class="activeStep >= 1 && produto.tipo_produto == 'normal' ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      2. Imagens
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Tamanhos"
                      v-if="produto.tipo_produto == 'pizza'"
                      :class="activeStep >= 1 && produto.tipo_produto == 'pizza' ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      2. Tamanhos
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Pricing"
                      v-if="produto.tipo_produto == 'normal'"
                      :class="activeStep >= 2 && produto.tipo_produto == 'normal' ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      3. Valores
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Bordas"
                      v-if="produto.tipo_produto == 'pizza'"
                      :class="activeStep >= 2 && produto.tipo_produto == 'pizza' ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      3. Bordas
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Massas"
                      v-if="produto.tipo_produto == 'pizza'"
                      :class="activeStep >= 3 && produto.tipo_produto == 'pizza' ? activeClass : ''"
                      @click="activeStep = 3"
                    >
                      4. Massas
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Horários"
                      v-if="produto.tipo_produto == 'normal'"
                      :class="activeStep >= 4 ? activeClass : ''"
                      @click="activeStep = 4"
                    >
                      4. Horários
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Sabores"
                      v-if="produto.tipo_produto == 'pizza'"
                      :class="activeStep >= 4 ? activeClass : ''"
                      @click="activeStep = 4"
                    >
                      5. Sabores
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Horários"
                      v-if="produto.tipo_produto == 'pizza'"
                      :class="activeStep >= 5 ? activeClass : ''"
                      @click="activeStep = 5"
                    >
                      6. Horários
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Adicionais"
                      v-if="produto.tipo_produto == 'normal'"
                      :class="activeStep >= 4 && produto.tipo_produto == 'normal' ? activeClass : ''"
                      @click="activeStep = 4"
                    >
                      5.Grupo de Adicionais
                    </button>
                  </div>
                </div>
              </div>
              <!--form panels-->
              <div class="row">
                <div class="m-auto col-12 col-lg-12">
                  <form class="mb-8 multisteps-form__form">
                    <!--single form panel-->
                    <product-info :class="loading == false && activeStep === 0 ? activeClass : ''" />
                    <!--single form panel-->
                    <media :class="activeStep === 1 && produto.tipo_produto == 'normal'? activeClass : ''" />
                    <!--single form panel-->
                    <tamanhos :class="activeStep === 1 && produto.tipo_produto == 'pizza'? activeClass : ''" />
                    <!--single form panel-->
                    <bordas :class="activeStep === 2 && produto.tipo_produto == 'pizza'? activeClass : ''" />
                    <!--single form panel-->
                    <pricing :class="activeStep === 2 && produto.tipo_produto == 'normal'? activeClass : ''" />
                    <!--single form panel-->
                    <horarios :class="activeStep === 3 && produto.tipo_produto == 'normal' ? activeClass : ''" />
                    <!--single form panel-->
                    <massas :class="activeStep === 3 && produto.tipo_produto == 'pizza' ? activeClass : ''" />
                    <!--single form panel-->
                    <adicionais :class="activeStep === 4 && produto.tipo_produto == 'normal' ? activeClass : ''" />
                    <!--single form panel-->
                    <sabores :class="activeStep === 4 && produto.tipo_produto == 'pizza' ? activeClass : ''" />
                    <!--single form panel-->
                    <horarios :class="activeStep === 5 && produto.tipo_produto == 'pizza' ? activeClass : ''" />
                  </form>


                    <div class="mt-4 button-row d-flex col-12" style="
                    margin: 0px !important;
                    padding-top: 0px !important;
                ">
                      <soft-button
                        type="button"
                        color="dark"
                        variant="gradient"
                        class="mb-0 ms-auto js-btn-next"
                        title="Next"
                        @click="this.salvarProduto"
                        style="background: green;"
                        >Salvar</soft-button
                      >
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader v-if="loading"/>
        <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>

      </div>
      
  </template>
  
  <script>
    import ProductInfo from "./components/ProductInfo.vue";
    import Media from "./components/Media.vue";
    import Adicionais from "./components/Adicionais.vue";
    import Horarios from './components/Horarios.vue'
    import Pricing from "./components/Pricing.vue";
    import Tamanhos from "./components/Tamanhos.vue";
    import Bordas from "./components/Bordas.vue";
    import Massas from "./components/Massas.vue";
    import Sabores from "./components/Sabores.vue";
    import "@/assets/scss-new/soft-ui-dashboard.scss";
    // importa o componente de Loader 
    import Loader from '@/components/Loader.vue'

    import "@/assets/css/nucleo-icons.css";
    import "@/assets/css/nucleo-svg.css";
    
    import moment from 'moment'

  import setNavPills from "@/assets/js/nav-pills.js";
  import SoftButton from "@/novocomponents/SoftButton.vue";
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
  export default {
    name: "ProfileOverview",
    components: {
      ProductInfo,
      Media,
      Adicionais,
      Pricing,
      Horarios,
      SoftButton,
      Loader,
      DialogMensagem,
      Tamanhos,
      Bordas,
      Massas,
      Sabores
    },
    data() {
      return {
        showMenu: false,
        activeClass: "js-active position-relative",
        activeStep: 0,
        formSteps: 4,
        loading:false,
        dialog_resposta:false,
        titulo:'',
        resposta:{
          titulo:'',
          mensagem:''
        },
        cadastrando:true,
        produto:{
          "nome_produto": "",
          "tipo_produto": "normal",
          "status": true,
          "descricao": "",
          "serve_quantidade_pessoas": "1",
          "catalog_id": null,
          "valor": 0,
          "valor_desconto": 0,
          "print_name":null,
          "imagens": [
          ],
          "horario_disponivel": [
              {
                "de": "00:00:00",
                "ate": "23:59:59",
                "segunda": true,
                "terca": true,
                "quarta": true,
                "quinta": true,
                "sexta": true,
                "sabado": true,
                "domingo": true
            }
          ],
          "tipo_integracao": [
            {
              "codigo": 1
            },
            {
              "codigo": 2
            }
          ],
          "categoria": {
            "produto_categoria_id": null
          },
          "subcategoria": [],
          "restricoes": [],
          "complementos": [
           
          ],
          "tamanhos":[],
          "massas":[],
          "bordas":[],
          "sabores":[],
          "valores": {
            "1": {
                "valor": null,
                "valor_desconto": null
            },
            "2": {
                "valor": null,
                "valor_desconto": null
            }
          }
        }
      };
    },
    async mounted() {
      console.log(this.$route)
      if(this.$route && this.$route.params && this.$route.params.produto_id){
        this.loading = true;
        var resp = await store.dispatch('getProduto',this.$route.params.produto_id);

        if(resp.data && resp.data.produto_id){
          this.produto = resp.data
          this.cadastrando =false;
          this.loading = false;
        }else{
          this.resposta.titulo = 'Produto não encontrado';
          this.dialog_resposta = true;
          this.loading = false;
        }
      }
      if(this.$route && this.$route.meta && this.$route.meta.type){
        this.produto.tipo_produto = this.$route.meta.type
        if(this.produto.tipo_produto == 'pizza'){
          this.formSteps = 5
        }
      }

      console.log(this.produto)
      this.$store.state.isAbsolute = true;
      setNavPills();
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
        console.log(this.activeStep)
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
        console.log(this.activeStep)
      },
      adicionarHorario(){
        this.produto.horario_disponivel.push({
          "de": "08:00:00",
          "ate": "23:59:59",
          "segunda": true,
          "terca": true,
          "quarta": true,
          "quinta": true,
          "sexta": true,
          "sabado": true,
          "domingo": true
        })
      },
      removerHorario(index){
        this.produto.horario_disponivel.splice(index, 1)
      },
      adicionarComplemento(){
        this.produto.complementos.push({
            "codigo_grupo_complemento":null,
            "min": 1,
            "max": 2,
            "nome": "",
            "por_quantidade": true,
            "status": true,
            "complementos":[]
        })
      },
      removerComplemento(index){
        this.produto.complementos.splice(index, 1)
      },
      adicionarComplementoComplementos(indexCom){
        this.produto.complementos[indexCom].complementos.push({
          "codigo_complemento":null,
          "nome": "",
          "descricao": "",
          "status": 1,
          "valores": {
              "1": {
                  "valor": 0,
                  "valor_desconto": 0
              },
              "2": {
                  "valor": 0,
                  "valor_desconto": 0
              }
          }
        })
      },
      adicionarSaborTamanho(indexCom){
        this.produto.sabores[indexCom].valores_tamanho.push({
          "codigo_tamanho": "",
          "valores": {
              "1": {
                "valor": 0,
                "valor_desconto": 0
              },
              "2": {
                "valor": 0,
                "valor_desconto": 0
              },
              "valor_ifood": "0,00",
              "valor_venda": "0,00",
              "valor_ecommerce": "0,00"
          }
        })
      },
      removerComplementos(indexCom,index){
        this.produto.complementos[indexCom].complementos.splice(index, 1)
      },
      removerSaborTamanho(indexCom,index){
        this.produto.sabores[indexCom].valores_tamanho.splice(index, 1)
      },
      adicionarTamanho(){
        this.produto.tamanhos.push({
            "codigo_tamanho": moment().format('x'),
            "nome": "",
            "status": true,
            "quantidade_fatias": "8",
            "valores": {
                "1": {
                    "valor": 0,
                    "valor_desconto": 0
                },
                "2": {
                    "valor": 0,
                    "valor_desconto": 0
                }
            },
            "imagens": null,
            "fracoes_int":10,
            "fracoes_aceitas": [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
            ]
        })
        console.log(  this.produto.tamanhos)
      },
      removerTamanho(index){
        this.produto.tamanhos.splice(index, 1)
      },
      adicionarBorda(){
        this.produto.bordas.push({
            "codigo_borda": moment().format('x'),
            "nome": "",
            "status": true,
            "valores": {
                "1": {
                    "valor": 0,
                    "valor_desconto": 0
                },
                "2": {
                    "valor": 0,
                    "valor_desconto": 0
                }
            },
        })
        console.log(  this.produto.bordas)
      },
      removerBorda(index){
        this.produto.bordas.splice(index, 1)
      },
      adicionarMassa(){
        this.produto.massas.push({
            "codigo_massa": moment().format('x'),
            "nome": "",
            "status": true,
            "valores": {
                "1": {
                    "valor": 0,
                    "valor_desconto": 0
                },
                "2": {
                    "valor": 0,
                    "valor_desconto": 0
                }
            },
        })
        console.log(  this.produto.massas)
      },
      removerMassa(index){
        this.produto.massas.splice(index, 1)
      },
      adicionarSabor(){
        this.produto.sabores.push({
          "codigo_sabor": moment().format('x'),
          "nome": "",
          "descricao": "",
          "status": true,
          "valores": {
            "valor_ifood": "0,00",
            "valor_venda": "0,00",
            "valor_ecommerce": "0,00"
          },
          "restricoes": [],
          "valores_tamanho": [
              
          ]
        })
        console.log(  this.produto.sabores)
      },
      removerSabor(index){
        this.produto.sabores.splice(index, 1)
      },
      async salvarProduto(){
        this.loading = true;
        var resp = await store.dispatch('postProdutos',this.produto);
        this.resposta.titulo = '';
        this.resposta.mensagem = '';
        if(resp.data && resp.data.error && resp.data.error[0]){
          for (let index = 0; index < resp.data.error.length; index++) {
            const error = resp.data.error[index];
            this.resposta.mensagem+= error.message + "\n";
            console.log('aquii',error.message )
          }
          this.resposta.titulo = 'Preenchimento inválido';
          this.dialog_resposta = true;
          this.loading = false;
          console.log(this.resposta)
        }else if(resp.data.sucesso == true){
          this.resposta.titulo = this.cadastrando ? 'Cadastrado com Sucesso' : 'Alterado com Sucesso';
          this.dialog_resposta = true;
          this.loading = false;

        }
				this.$router.push('/admin/produtos')
        
      }
    },
  };
  </script>
  