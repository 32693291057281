<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Informações do {{$parent.produto.tipo_produto == 'normal' ? 'Produto' : 'Pizza'}}</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-3" v-if="$parent.produto.tipo_produto == 'normal'">
          <label>Nome *</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder="Lanche"
            v-model="$parent.produto.nome_produto"
          />
        </div>
        <div class="col-12 col-sm-3">
          <label>Categoria *</label>
          <select
            id="choices-category"
            class="form-control"
            name="choices-category"
            v-model="$parent.produto.categoria.produto_categoria_id"
          >
            <!-- <option v-for="(categoria,index) in categorias" :key="index" :value="categoria.produto_categoria_id"
             :selected="$parent.produto.produto_categoria_id == categoria.produto_categoria_id"
             >{{ categoria.nome }}</option> -->
          </select>
        </div>
        <div class="col-12 col-sm-3">
          <label>Impressora *</label>
          <select
            id="print"
            class="form-control"
            name="print"
            v-model="$parent.produto.print_name"
          >
            <!-- <option v-for="(categoria,index) in categorias" :key="index" :value="categoria.produto_categoria_id"
             :selected="$parent.produto.produto_categoria_id == categoria.produto_categoria_id"
             >{{ categoria.nome }}</option> -->
          </select>
        </div>
        <div class="col-12 col-sm-3" v-if="$parent.produto.tipo_produto == 'normal'">
          <label>Serve quantas pessoas? *</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder="Lanche"
            v-model="$parent.produto.serve_quantidade_pessoas"
          />
        </div>
        <div class="col-12 col-sm-3 " v-if="false">
          <label>Catalogo Ifood</label>
          <select
            id="choices-catalogo-ifood"
            class="form-control"
            name="choices-catalogo-ifood"
            v-model="$parent.produto.catalog_id"
          >
            <!-- <option v-for="(categoria,index) in categorias" :key="index" :value="categoria.produto_categoria_id"
             :selected="$parent.produto.produto_categoria_id == categoria.produto_categoria_id"
             >{{ categoria.nome }}</option> -->
          </select>
        </div>
      </div>
      <div class="row" v-if="$parent.produto.tipo_produto == 'normal'">
        <div class="col-12">
          <label class="mt-4">Descrição</label>
          <p class="text-xs form-text text-muted ms-1 d-inline"></p>
          <div id="edit-description" class="h-50" :content="this.$parent.produto.descricao">
            
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          v-on:click="this.$parent.nextStep"
          >Próximo</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import Choices from "choices.js";
import SoftButton from "@/novocomponents/SoftButton.vue";

	// importa o store
	import store from '@/store'
export default {
  name: "ProductInfo",
  components: {
    SoftButton,
  },

  data: () => ({
    categorias:[],
    dispositivos:[],
    quill:null,iniciando:false,
    descricao:'',
    ch_cate:null,
    ch_print:null
  }),  

  async onQuill(){
  },
  async mounted() {
    await this.init()
    if (document.getElementById("edit-description")) {
      // eslint-disable-next-line no-unused-vars
      this.quill = new Quill("#edit-description", {
        theme: "snow", // Specify theme in configuration
        contentType:'html'
      });


      this.quill.on('text-change', ()=>{
        this.descricao = (document.getElementsByClassName("ql-editor")[0].innerHTML);
      });
    }

    if (document.getElementById("tipo_produto")) {
      let element = document.getElementById("tipo_produto");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
  watch: {
    // whenever question changes, this function will run
    descricao(newQuestion) {
      this.$parent.produto.descricao = newQuestion
    },

    // whenever question changes, this function will run
    '$parent.produto'(newQuestion) {
      if(this.iniciando == false){
        this.ch_cate.setChoiceByValue(newQuestion.produto_categoria_id);
        document.getElementsByClassName("ql-editor")[0].innerHTML = newQuestion.descricao
        this.ch_print.setChoiceByValue(newQuestion.print_name);
        this.iniciando = true;
        
      }
    },
  },
  methods: {
    async init() {
      this.iniciando = false;
      var resp = await store.dispatch('getCategoriaSelect',{});
      this.categorias = resp.data.dados
      var resp_dis = await store.dispatch('getDispositivoSelect',{});
      this.dispositivos = resp_dis.data.dados

      if (document.getElementById("choices-category")) {
        var element = document.getElementById("choices-category");
        this.ch_cate = new Choices(element, {
          searchEnabled: false,
        });

        this.ch_cate.setChoices(
          this.categorias,
          'produto_categoria_id',
          'nome',
          false,
        );
      }
      if (document.getElementById("print")) {
        var element_print = document.getElementById("print");
        this.ch_print = new Choices(element_print, {
          searchEnabled: false,
        });

        this.ch_print.setChoices(
          this.dispositivos,
          'id',
          'nome',
          false,
        );
      }
    },
    

  },

};
</script>
